
:root {
  --white: #fff;
  --orange: #f36c00;
}

.outer-container {
  max-width: 800px;
  min-width: 320px;
  width: fit-content;
  align-self: center;
  overflow: hidden;
  display:flex;
}

.outer-container > div {
  flex: 1;
}

.outer-container .carousel-wrapper {
  /* background: rgba(0, 0, 0, 0.35); */
  display: flex;
  flex-direction: column;
}

.outer-container .content {
  background-repeat: no-repeat;
  background-image: url('/public/images/waves3.svg');
  background-size: cover;
  background-color: #fff;
}

.outer-container .content.dark {
  background-color: #3A3A3A;
}

button {
  cursor: pointer;
}

.content {
  color: #000;
  max-width: 422px;
  margin-left: 20px;
}

.carousel-button {
  display: flex;
  align-self: center;

  max-width: 50px;
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}

.carousel-button path {
  fill: var(--white);
  filter: drop-shadow(0px 0px 1px rgba(0,0,0,0.75));
}

.carousel-wrapper {
  max-width: 100px;
  padding: 0;
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 100px;
  color: var(--white);
}

.slides {
  align-self: flex;
  flex: 1;
  height: 100%;
  width: 100px;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  width: 100px;
  height: 100%;
  padding: 0;
}

.carousel-inner::before,
.carousel-inner::after {
  content: '';
  z-index: 9;
  position: absolute;
  width: 98%;
  height: 45px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  z-index: 9;
}

.carousel-inner::after {
  bottom: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgb(0, 0, 0, 0) 100%);
  z-index: 9;
}

.carousel-inner.dark::before {
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 255, 0) 0%, rgba(20, 20, 20, 0.9) 100%);
  z-index: 9;
}

.carousel-inner.dark::after {
  bottom: 0;
  background: linear-gradient(0deg, rgba(20, 20, 20, 0.9) 0%, rgb(0, 0, 0, 0) 100%);
  z-index: 9;
}

.carousel-item {
  position: absolute;
  background: none;
  filter: grayscale();
  height: 120px;
  padding: 0;
  margin-top: 0px;
  transition: transform 0.4s ease, opacity 0.4s ease;
  opacity: 0;
  background-color: #fff;
}

.carousel-item.active{
  filter: none;
}

.carousel-item.visible {
  opacity: 1;
  background-repeat: no-repeat;
  background-image: url('/public/images/waves3.svg');
  background-size: cover;
}

.carousel-item.darkBg {
  background-color: #3A3A3A;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

#root{
  overflow-x: hidden;
}

.titleFont{
  font-family: 'Darker Grotesque', sans-serif !important;
}

.mainCont{
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.gradient{
  background: linear-gradient(90deg, rgba(252, 251, 251, 0.20) 0%, rgba(91, 81, 81, 0.10) 100%);
  backdrop-filter: blur(3px);
}

.gradient2{
  background: linear-gradient(137deg, rgba(241, 241, 241, 0.45) -4.26%, rgba(194, 194, 194, 0.19) 102.22%);
}

.gradientCard{
  background: linear-gradient(134deg, rgba(241, 241, 241, 0.45) 0%, rgba(194, 194, 194, 0.19) 100%);
}

.customShadow{
  box-shadow: 0px 4px 72.80999755859375px 0px rgba(0, 0, 0, 0.17);
}

.faqBG{
  background: linear-gradient(137deg, rgba(241, 241, 241, 0.45) -4.26%, rgba(194, 194, 194, 0.19) 102.22%);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  backdrop-filter: blur(7.5px);
}


.gradientDark{
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.10) 100%);
  backdrop-filter: blur(5px);
}

.gradientCardDark{
  background: linear-gradient(255deg, rgba(24, 26, 37, 0.80) 7.66%, rgba(8, 11, 23, 0.16) 103.71%);
}

.gradientCardDark2{
  background: linear-gradient(137deg, #13141E -4.26%, rgba(17, 18, 28, 0.70) 102.22%);
}

.gradient3{
  background: linear-gradient(137deg, #2a2b41 -4.26%, rgba(25, 26, 41, 0.7) 102.22%);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}